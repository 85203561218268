<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter :termOnly="true" @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <span :id="`BTN_IMPORT_DOCUMENT_${id}`">
          <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('template.button.import_document') }}
        </b-popover>
        <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
          <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('template.button.export_document') }}
        </b-popover>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('template')" href="#">
                <span class="action-item-label">{{ $t('template.coloring.template') }}</span><font-awesome-icon class="active-check" v-if="coloring.template" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="template-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :sideBar="false"
            :serverSideInfiniteScroll="true"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
          <!-- <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button> -->
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <TaskTemplateModal v-if="templateShow" :id="templateId" :show.sync="templateShow" @success="templateSuccess" :title="templateTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('template.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('template.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('template.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'template.confirmation.delete_plural':'template.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'desc', text: $t('task.field.description') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'name', text: $t('task.field.name') }, { value: 'tag', text: $t('field.tag') }]" :mode="'TASK_TEMPLATE'" :show="docImportShow"
      :title="$t('template.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { objectClone, invertColor } from '@/helpers';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';

import { strRandom, addTags } from '@/helpers';

import { templateProjectService, viewProfileService, templateProjectLinkTagService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
import TaskTemplateModal from '@/components/modal/TaskTemplateModal';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const p = self.buildParams(params);
      if(self.isFirstLoad && self.selectedId != null) {
        p.sight = self.selectedId;
      }
      templateProjectService.list(p).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);

        //Preselect provided project on modal show.
        if(self.isFirstLoad && self.selectedId != null) {
          self.selected.push(self.selectedId);
          const rowIndex = response.arg_index;
          const pageSize = self.gridApi.paginationGetPageSize();
          const currentPage = parseInt(rowIndex / pageSize) + 1;
          self.gridApi.paginationGoToPage(currentPage);
          params.api.refreshServerSide({purge: true});
          self.isFirstLoad = false;
          return;
        }

        if(self.modalShow) {
          params.api.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = objectClone(self.selected);
            let firstRowIndex = null;
            params.api.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                if (firstRowIndex == null) {
                  firstRowIndex = node.rowIndex;
                }
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
            if (firstRowIndex != null) {
              params.api.ensureIndexVisible(firstRowIndex, 'top');
            }
          }
        }
        params.api.hideOverlay();        
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        params.successCallback([], self.totalRecords);
        self.gridApi.showNoRowsOverlay();
      });
    }
  }
}

export default {
  name: 'TaskTemplateSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    TaskTemplateModal,
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    detailLinkCellRenderer: DetailLinkCellRenderer,
    costCellRenderer: CostCellRenderer,
    durationCellRenderer: DurationCellRenderer,
    'colorCellRenderer': ColorCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    selectedId: {
      type: String,
      default: null
    },
    selectedIds: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      id: `TASK_TEMPLATE_LIST_${strRandom(5)}`,
      permissionName: 'TEMPLATE__PROJECT',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      // disableOk: true,
      selected: [],

      templateId: null,
      templateShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: "",

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      
      coloring: {
        none: true,
        template: false
      },
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'task_template_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        // self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('template.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc',
        cellStyle: params => {
            if (params.data &&
                params.data.color &&
                self.coloring.template) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
            }
        }
      },
      {
        headerName: this.$t('template.field.description'),
        field: 'description'
      },
      {
        headerName: this.$t('template.field.estimatedCost'),
        field: 'estimatedCost',
        cellRenderer: 'costCellRenderer',
        hide: true
      },
      {
        headerName: this.$t('template.field.estimatedCostNet'),
        field: 'estimatedCostNet',
        cellRenderer: 'costCellRenderer',
        hide: true
      },
      {
        headerName: this.$t('template.field.estimatedDuration'),
        field: 'estimatedDuration',
        cellRenderer: 'durationCellRenderer',
        hide: true
      },
      {
        headerName: this.$t('field.tag'),
        field: 'tag'
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer'
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier'
      }
    ];
    const linkedEntities = [
      { selector: 'PROJECT_TEMPLATE.TAG', field: 'tag', properties: ['name'] }
    ]
    filterOutViewDenyProperties(this.columnDefs, 'PROJECT_TEMPLATE', linkedEntities);
    this.columnDefs.sort(columnDefSortFunc);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.prepareSelected();
    this.loadViewProfile();
  },
  created() {
    this.updateModalShow(this.show);
    this.isFirstLoad = true;
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.isFirstLoad = true;
        this.prepareSelected();
        await this.loadViewProfile();
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    templateTitle() {
      return this.templateId && this.templateId.indexOf('TEMPLATE_NEW') == -1? this.$t('template.detail'): this.$t('template.new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('template.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('template.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const self = this;
    
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.templateId = `TEMPLATE_NEW_${strRandom(5)}`;
      } else {
        this.templateId = this.selected[0];
      }
      this.templateShow = true;
      this.resetAlert();
    },
    templateSuccess(payload) {
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('template.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`template.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      // const toDeleteIds = this.selected.map(i => { return { uuId: i } });
      await templateProjectService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('template.delete_partial');
          alertMsgDetailTitle = this.$t(`template.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`template.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`template.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name} });
      this.$emit('ok', { ids: [...this.selected], details: details });
      this.$emit('input', [...this.selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
    },
    openDetail(id){
      this.templateId = id;
      this.templateShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('template.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          templateProjectService.clone(this.selected[0], { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('template.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.TEMPLATE')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('template.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.task_template_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.task_template_selector_list);
            self.coloring.none = self.settings.task_template_selector_coloring ? self.settings.task_template_selector_coloring.none : true;
            self.coloring.template = self.settings.task_template_selector_coloring ? self.settings.task_template_selector_coloring.template : false;
          } else {
            this.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(/** self */) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
      
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
      
        const self = this;
        this.gridApi.exportDataAsExcel({ 
          fileName: 'Task Templates'
          , sheetName: 'Task Templates'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
        
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridApi.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addTemplates(items);
      this.inProgressShow = false;
      this.gridApi.refreshServerSide({ purge: true });
    },
    async addTemplates(items) {
      this.inProgressLabel = this.$t('template.progress.importing', [0]);
      let percentage = 0;
      for (const item of items) {
        const data = {
          name: item.name,
          description: item.desc,
          identifier: item.identifier,
          color: item.color
        }
        
        const result = await templateProjectService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result &&
            item.tag) {
          await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), templateProjectLinkTagService);
        }
        
        percentage++;
        this.inProgressLabel = this.$t('template.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    prepareSelected() {
      this.selected.splice(0, this.selected.length, ...this.selectedIds);
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['task_template_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.template) {
        return data.color;
      }
    }
  }
}


</script>